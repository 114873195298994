import React, {useEffect, useRef} from 'react';
import {Box, Text, OrderedList, ListItem} from '@chakra-ui/react';
import LeftNav from '../../components/Leftsidenav/Leftsidenav';
import useIsInViewport from '../../utils/useOnScreen/useOnScreen';

export const Terms = () => {
  useEffect(() => {
    document.title = 'Terms of service';
  }, []);

  const account_termsRef = useRef(null);
  const account_activationRef = useRef(null);
  const veergeRef = useRef(null);
  const yourRef = useRef(null);
  const paymentRef = useRef(null);
  const confidentRef = useRef(null);
  const limitationRef = useRef(null);
  const intellectualRef = useRef(null);
  const betaRef = useRef(null);
  const feedbackRef = useRef(null);
  const privacyRef = useRef(null);
  const termsRef = useRef(null);
  const modificationRef = useRef(null);
  const generalRef = useRef(null);

  const account_termsCheck = useIsInViewport(account_termsRef);
  const account_activationCheck = useIsInViewport(account_activationRef);
  const veergeCheck = useIsInViewport(veergeRef);
  const yourCheck = useIsInViewport(yourRef);
  const paymentCheck = useIsInViewport(paymentRef);
  const confidentCheck = useIsInViewport(confidentRef);
  const limitationCheck = useIsInViewport(limitationRef);
  const intellectualCheck = useIsInViewport(intellectualRef);
  const betaCheck = useIsInViewport(betaRef);
  const feedbackCheck = useIsInViewport(feedbackRef);
  const privacyCheck = useIsInViewport(privacyRef);
  const termsCheck = useIsInViewport(termsRef);
  const modificationCheck = useIsInViewport(modificationRef);
  const generalCheck = useIsInViewport(generalRef);

  const articleContent = [
    {check: account_termsCheck, id: 'account_terms', title: 'Account Terms'},
    {check: account_activationCheck, id: 'account_activation', title: 'Account Activation'},
    {check: veergeCheck, id: 'veerge', title: 'Veerge Rights'},
    {check: yourCheck, id: 'your', title: 'Your Responsibilities '},
    {check: paymentCheck, id: 'payment', title: 'Payment of Fees and Taxes'},
    {check: confidentCheck, id: 'confident', title: 'Confidentiality'},
    {
      check: limitationCheck,
      id: 'limitation',
      title: 'Limitation of Liability and Indemnification ',
    },
    {
      check: intellectualCheck,
      id: 'intellectual',
      title: 'Intellectual Property and Your Materials',
    },
    {check: betaCheck, id: 'beta', title: 'Beta Services'},
    {check: feedbackCheck, id: 'feedback', title: 'Feedback and Reviews'},
    {check: privacyCheck, id: 'privacy', title: 'Privacy Policy'},
    {check: termsCheck, id: 'terms', title: 'Term and Termination'},
    {check: modificationCheck, id: 'modification', title: 'Modifications'},
    {check: generalCheck, id: 'general', title: 'General Conditions'},
  ];

  const relatedContent = [
    {link: '/first_time_setup', text: 'First time setup'},
    {link: '/approved_what_next', text: 'Approved, what next?'},
    {link: '/terms', text: 'Terms of service'},
    {link: '/users_experience', text: 'End-to-End experience'},
  ];

  return (
    <div className="main">
      {/* <LeadRightNav /> */}
      <Text className="head_">TERMS OF SERVICE</Text>
      <Text className="publish">Last updated on: May 23, 2022</Text>
      <LeftNav articleContent={articleContent} relatedContent={relatedContent} />
      <Box id="invite">
        <Text className="content" lineHeight={'25px'}>
          <Text as="p">
            Welcome to Veerge! By signing up for a Veerge Account (as defined in Section 1) or using
            any Veerge Services (as defined below), you are agreeing to be bound by the following
            terms and conditions (the <b>"Terms of Service"</b>). As used in these Terms of Service,{' '}
            <b>"we,"</b> <b>"us,"</b> <b>"our,"</b> and <b>"Veerge"</b> refer to the applicable
            Veerge Contracting Party (Myxellia Inc.), and <b>"you"</b>refers to the Veerge User (if
            registering for or using a Veerge Service as an individual) or the business employing
            the Veerge User (if registering for or using a Veerge Service as a business) and any of
            its affiliates. Veerge provides seamlessly integrated apps and services that enable
            property developers to unify their business activities. Our platform includes a range of
            tools for property developers to build and customize web applications, manage offerings,
            inventory, payments, fulfillment, business operations, and engage with existing and
            potential customers. Any service or services offered by Veerge are collectively referred
            to in these Terms of Service as the "Services." Any new features or tools added to the
            current Services will also be subject to the Terms of Service. You can review the
            current version of the Terms of Service at any time by visiting
            <a href="https://www.Veerge.com/legal/terms" as="span" textDecoration={'underline'}>
              {' '}
              https://www.Veerge.com/legal/terms
            </a>{' '}
            Before you can sign up for a Veerge Account or use any Veerge Service, you must read,
            agree with, and accept all of the terms and conditions contained or expressly referenced
            in these Terms of Service, including the Privacy Policy.
          </Text>
        </Text>
      </Box>

      <OrderedList className="content" fontSize={'24px'} fontWeight={'600'}>
        <ListItem id="account_terms" ref={account_termsRef}>
          <Text className="content_head">Account Terms</Text>
          <OrderedList className="content">
            <ListItem>
              To access and use the Services, you must register for a Veerge account ("Account"). To
              complete your Account registration, you must provide us with your full legal name,
              business address, phone number, a valid email address, and any other information
              indicated as required. Veerge reserves the right to reject your application for an
              Account or cancel an existing Account, at our sole discretion, for any reason.
            </ListItem>
            <ListItem>
              You must be at least 18 years old, or the age of majority in your jurisdiction of
              residence, whichever is older, to open an Account and use the Services.
            </ListItem>
            <ListItem>
              By using the Services provided by Veerge, you confirm that you are using them for
              business purposes and not for personal, household, or family use.
            </ListItem>
            <ListItem>
              You acknowledge that Veerge will primarily communicate with you through the email
              address you provide during Account registration or update. You must regularly check
              the email address you provided and ensure that it is capable of sending and receiving
              messages. Your email communications with Veerge can only be authenticated if they
              originate from your provided email address.
            </ListItem>
            <ListItem>
              You are solely responsible for maintaining the security of your Account and password.
              Veerge will not be liable for any loss or damage resulting from your failure to
              safeguard your Account and password. We may request additional security measures at
              any time and reserve the right to adjust these requirements at our discretion.
            </ListItem>
            <ListItem>
              Technical support for the Services is exclusively provided to Veerge Users. For any
              questions regarding the Terms of Service, please contact Veerge Support.
            </ListItem>
            <ListItem>
              You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of
              the Service, the use of the Services, or access to the Services without the express
              written permission of Veerge.
            </ListItem>
            <ListItem>
              You agree not to bypass, work around, or circumvent any technical limitations of the
              Services, use any tool to enable disabled features or functionalities in the Services,
              or engage in the decompiling, disassembling, or reverse engineering of the Services.
            </ListItem>
            <ListItem>
              You agree not to access the Services or monitor any material or information from the
              Services using any robot, spider, scraper, or other automated means.
            </ListItem>
            <ListItem>
              You understand that your Materials may be transferred unencrypted and may involve (a)
              transmissions over various networks, and (b) changes to conform and adapt to technical
              requirements of connecting networks or devices. "Materials" refer to your trademarks,
              copyrighted content, any products or services you offer through the Services
              (including descriptions and prices), and any photos, images, videos, graphics, written
              content, or other data provided or made available by you or your affiliates to Veerge
              or its affiliates.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="account_activation" ref={account_activationRef}>
          <Text className="content_head">Account Activation</Text>
          <Text className="content_subhead">2.1 Veerge Owner </Text>
          <OrderedList className="content">
            <ListItem>
              The person who signs up for the Service by opening an Account will be the contracting
              party ("Veerge Owner") for the purposes of our Terms of Service and will be authorized
              to use any corresponding Account provided by Veerge in connection with the Service,
              subject to Section 2.1.2.
            </ListItem>
            <ListItem>
              If you are signing up for the Services on behalf of your employer, your employer will
              be the Veerge Owner. In such cases, you must use your employer-issued email address
              and represent and warrant that you have the authority to bind your employer to our
              Terms of Service.
            </ListItem>
            <ListItem>
              Your Veerge account can only be associated with one Veerge Owner. You agree to use
              Veerge Checkout for your web application. "Web Application" refers to the online
              application or any Veergefront built on top of the Veergefront API.
            </ListItem>
          </OrderedList>
          <Text className="content_subhead">2.2 Staff Accounts</Text>
          <OrderedList className="content">
            <ListItem>
              Depending on your Veerge pricing plan, you can create one or more staff accounts
              ("Staff Accounts") to grant access to other individuals to your Account. Each Staff
              Account must include a full legal name and a valid email address. Through Staff
              Accounts, the Veerge Owner can set permissions and control the level of access that
              Staff Accounts have to specific business information (e.g., you can limit Staff
              Account access to sales information).
            </ListItem>
            <ListItem>
              The Veerge Owner is responsible for: (a) ensuring that their employees, agents, and
              subcontractors, including those using Staff Accounts, comply with these Terms of
              Service, and (b) any breach of these Terms of Service by the Veerge Owner's employees,
              agents, or subcontractors. The Veerge Owner acknowledges and agrees that they will be
              responsible for the performance of all obligations under the Agreement, regardless of
              whether they sublicense or subcontract any such obligations to third parties,
              including affiliates or subsidiaries of the Veerge Owner.
            </ListItem>
            <ListItem>
              The Veerge Owner and the users under Staff Accounts are collectively referred to as
              "Veerge Users."
            </ListItem>
          </OrderedList>
          <Text className="content_subhead">2.3 Domain Names</Text>
          <Text className="content">
            When you purchase a domain name through Veerge, domain registration will be set to
            automatically renew each year as long as your Veerge Account remains active. You
            acknowledge that it is your sole responsibility to deactivate the auto-renewal function
            if you choose to do so.
          </Text>
        </ListItem>

        <ListItem id="veerge" ref={veergeRef}>
          <Text className="content_head">Veerge Rights</Text>
          <OrderedList className="content">
            <ListItem>
              The Services offer a range of features and functionalities. However, not all Services
              or features will be available to all Property development companies at all times, and
              we are under no obligation to make any Services or features available in any
              jurisdiction. We reserve the right to modify the Services or any part thereof for any
              reason, without prior notice and at any time.
            </ListItem>
            <ListItem>
              Veerge does not pre-screen Materials, but we retain the sole discretion to refuse or
              remove any Materials from any part of the Services. This includes situations where we
              determine, at our sole discretion, that your offerings through the Services or the
              Materials uploaded or posted to the Services violate these Terms of Service.
            </ListItem>
            <ListItem>
              Verbal or written abuse of any kind, including threats of abuse or retribution,
              directed towards any Veerge employee, member, or officer will result in immediate
              termination of your Account.
            </ListItem>
            <ListItem>
              We reserve the right to provide our Services to your competitors and make no promise
              of exclusivity.
            </ListItem>
            <ListItem>
              In the event of a dispute regarding Account ownership, we reserve the right to request
              documentation to determine or confirm Account ownership. Such documentation may
              include, but is not limited to, a scanned copy of your business license,
              government-issued photo ID, or confirmation of your employment status with an entity.
            </ListItem>
            <ListItem>
              Veerge reserves the right, in our sole discretion, to determine rightful Account
              ownership and transfer an Account to the rightful Veerge Owner. If we are unable to
              reasonably determine the rightful Veerge Owner, without prejudice to our other rights
              and remedies, Veerge reserves the right to temporarily suspend or disable an Account
              until the resolution has been determined between the disputing parties.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="your" ref={yourRef}>
          <Text className="content_head">Your Responsibilities</Text>
          <OrderedList className="content">
            <ListItem>
              You acknowledge and agree to provide public-facing contact information, a refund
              policy, and fulfilment timelines on your Web application.
            </ListItem>
            <ListItem>
              You acknowledge and agree that any contract of sale made through the Services is
              directly between you and the customer. You are the seller of record for all properties
              you sell through the Services. You are responsible for the creation and operation of
              your Web application, your Materials, properties that you sell through the Services,
              and all aspects of the transactions between you and your customer(s). This includes,
              but is not limited to, authorising the charge to the customer for their purchase,
              processing refunds, fulfilling any sales or customer service obligations, handling
              fraudulent transactions, providing required legal disclosures, ensuring regulatory
              compliance, addressing alleged or actual violations of applicable laws (including
              consumer protection laws in any jurisdiction where you offer your services), and
              complying with these Terms of Service. You represent and warrant that your Web
              application, your Materials, and the services you offer through the Services will be
              true, accurate, and complete, and will not violate any applicable laws, regulations,
              or rights of third parties. For the avoidance of doubt, Veerge will not be the seller
              of record and will have no responsibility for your Web application or properties sold
              to customers through the Services.
            </ListItem>
            <ListItem>
              You are solely responsible for the properties that you sell through the Services,
              including their descriptions, prices, fees, taxes you calculate, any defects, required
              legal disclosures, regulatory compliance, offers, or promotional content. You must
              ensure compliance with all applicable laws and regulations.
            </ListItem>
            <ListItem>
              You may not use the Veerge Services for any illegal or unauthorised purpose, and you
              must not violate any laws in your jurisdiction (including copyright laws) or the laws
              applicable to you in your customers' jurisdictions. You agree to comply with all
              applicable laws, rules, and regulations, including obtaining and complying with any
              necessary licenses or permits required to operate your Web application. You are
              responsible for your use of the Service and for performing your obligations under
              these Terms of Service.
            </ListItem>
            <ListItem>
              You agree to use Veerge Checkout for any sales associated with your Web application.
              "Veerge Checkout" refers to Veerge's checkout experience that allows customers to
              enter their payment details.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="payment" ref={paymentRef}>
          <Text className="content_head">Payment of Fees and Taxes</Text>
          <OrderedList className="content">
            <ListItem>
              We bill subscription fees on a monthly basis. However, we may issue bills more
              frequently if we suspect fraudulent activity or a risk of non-payment. You are
              responsible for paying all applicable fees and charges for using the Services as
              described on the Veerge website. Payments must be made using a payment method
              supported by Veerge. You agree to pay all amounts due without setoff, counterclaim,
              deduction, or withholding. Fees and charges for new services or features will be
              effective when we post updated fees and charges on the Veerge website, unless
              otherwise stated in a notice. We may increase or add new fees and charges for any
              existing services you are using by providing at least 30 days' prior notice.
            </ListItem>
            <ListItem>
              Your users will be responsible for paying all applicable fees related to sales made
              through your web or mobile application. Veerge charges will continue until the
              Services are terminated and all outstanding fees have been paid in full. Unless
              otherwise specified, all fees and charges are in U.S. dollars.
            </ListItem>
            <ListItem>
              If payment is not made, we may suspend or revoke access to your account and the
              services. Your account will be reactivated upon payment of any outstanding fees.
              During any period of suspension, you may not be able to access your account, and your
              users may not have access to the web or mobile application. If outstanding fees remain
              unpaid for 60 days following the suspension date, Veerge reserves the right to
              terminate your account in accordance with Section 14.
            </ListItem>
            <ListItem>
              You are responsible for all applicable taxes arising from your subscription to or
              purchase of Veerge's products and services. You are solely responsible for
              determining, collecting, withholding, reporting, and remitting any applicable taxes,
              duties, fees, surcharges, and additional charges arising from sales made on your web
              application or your use of the Services. Any contract of sale made through the
              Services is directly between you and the customer.
            </ListItem>
            <ListItem>
              You must maintain an accurate location in the administrative console of your Veerge
              Store. If you change jurisdictions, you must promptly update your location in the
              administrative console.
            </ListItem>
            <ListItem>Veerge does not provide refunds.</ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="confident" ref={confidentRef}>
          <Text className="content_head">Confidentiality</Text>
          <OrderedList className="content">
            <ListItem>
              “Confidential Information” will include, but will not be limited to, any and all
              information associated with a party’s business and not publicly known, including
              specific business information, technical processes and formulas, software, customer
              lists, prospective customer lists, names, addresses and other information regarding
              customers and prospective customers, designs, sales, costs (including any relevant
              processing fees), price lists, and other unpublished financial information, business
              plans and marketing data, and any other confidential and proprietary information,
              whether or not marked as confidential or proprietary. Veerge’s Confidential
              Information includes all information that you receive relating to us, or to the
              Services, that is not known to the general public including information related to our
              security program and practices.
            </ListItem>
            <ListItem>
              Each party agrees to use the other party’s Confidential Information solely as
              necessary for performing its obligations under these Terms of Service and in
              accordance with any other obligations in these Terms of Service including this Section
              6. Each party agrees that it will take all reasonable steps, at least substantially
              equivalent to the steps it takes to protect its own proprietary information, to
              prevent the duplication, disclosure or use of any such Confidential Information, other
              than (i) by or to its employees, agents and subcontractors who must have access to
              such Confidential Information to perform such party’s obligations hereunder, who each
              will treat such Confidential Information as provided herein, and who are each subject
              to obligations of confidentiality to such party that are at least as stringent as
              those contained herein; or (ii) as required by any law, regulation, or order of any
              court of proper jurisdiction over the parties and the subject matter contained in
              these Terms of Service, provided that, if legally permitted, the receiving party will
              give the disclosing party prompt written notice and use commercially reasonable
              efforts to ensure that such disclosure is accorded confidential treatment.
              Confidential Information will not include any information that the receiving party can
              prove: (A) was already in the public domain, or was already known by or in the
              possession of the receiving party, at the time of disclosure of such information; (B)
              is independently developed by the receiving party without use of or reference to the
              other party’s Confidential Information, and without breaching any provisions of these
              Terms of Service; or (C) is thereafter rightly obtained by the receiving party from a
              source other than the disclosing party without breaching any provision of these Terms
              of Service.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="limitation" ref={limitationRef}>
          <Text className="content_head">Limitation of Liability and Indemnification</Text>
          <OrderedList className="content">
            <ListItem>
              You expressly understand and agree that, to the extent permitted by applicable laws,
              Veerge will not be liable for any direct, indirect, incidental, special, consequential
              or exemplary damages, including but not limited to, damages for loss of profits,
              goodwill, use, data or other intangible losses arising out of or relating to the use
              of or inability to use the Service or these Terms of Service (however arising,
              including negligence).
            </ListItem>
            <ListItem>
              You agree to indemnify and hold us and (as applicable) our parent, subsidiaries,
              affiliates, Veerge partners, officers, directors, agents, employees, and suppliers
              harmless from any claim or demand, including reasonable attorneys’ fees, made by any
              third party due to or arising out of (a) your breach of these Terms of Service or the
              documents it incorporates by reference; (b) or your violation of any law or the rights
              of a third party; or (c) any aspect of the transaction between you and your Customer,
              including but not limited to refunds, fraudulent transactions, alleged or actual
              violation of applicable laws (including but not limited to Federal and State consumer
              protection laws), or your breach of the Terms of Service.
            </ListItem>
            <ListItem>
              You will be responsible for any breach of the Terms of Service by your affiliates,
              agents or subcontractors and will be liable as if it were your own breach.
            </ListItem>
            <ListItem>
              Your use of the Services is at your sole risk. The Services are provided on an “as is”
              and “as available” basis without any warranty or condition, express, implied or
              statutory.
            </ListItem>
            <ListItem>
              Veerge does not warrant that the Services will be uninterrupted, timely, secure, or
              error-free.
            </ListItem>
            <ListItem>
              Veerge does not warrant that the results that may be obtained from the use of the
              Services will be accurate or reliable.
            </ListItem>
            <ListItem>
              Veerge is not responsible for any of your tax obligations or liabilities related to
              the use of Veerge’s Services.
            </ListItem>
            <ListItem>
              Veerge does not warrant that the quality of any products, services, information, or
              other materials purchased or obtained by you through the Services will meet your
              expectations, or that any errors in the Services will be corrected.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="intellectual" ref={intellectualRef}>
          <Text className="content_head">Intellectual Property and Your Materials</Text>
          <Text className="content_subhead">8.1. Your Materials</Text>
          <OrderedList className="content">
            <ListItem>
              We do not claim ownership of the Materials you provide to Veerge; however, we do
              require a license to those Materials. You grant Veerge a non-exclusive, transferable,
              sub-licensable, royalty-free, worldwide right and license to host, use, distribute,
              expose, modify, run, copy, store, publicly perform, communicate to the public
              (including by telecommunication), broadcast, reproduce, make available, display, and
              translate, and create derivative works of any Materials provided by you in connection
              with the Services. We may use our rights under this license to operate, provide, and
              promote the Services and to perform our obligations and exercise our rights under the
              Terms of Service. You represent, warrant, and agree that you have all necessary rights
              in the Materials to grant this license. You irrevocably waive any and all moral rights
              you may have in the Materials in favour of Veerge and agree that this waiver may be
              invoked by anyone who obtains rights in the materials through Veerge, including anyone
              to whom Veerge may transfer or grant (including by way of license or sublicense) any
              rights in the Materials.
            </ListItem>
            <ListItem>
              If you owned the Materials before providing them to Veerge then, despite uploading
              them to your Web application they remain yours, subject to any rights or licenses
              granted in the Terms of Service or elsewhere. You can remove your Web application at
              any time by deleting your Account. Removing your Web application does not terminate
              any rights or licenses granted to the Materials that Veerge requires to exercise any
              rights or perform any obligations that arose during the Term.
            </ListItem>
            <ListItem>
              You agree that Veerge can, at any time, review and delete any or all of the Materials
              submitted to the Services, although Veerge is not obligated to do so.
            </ListItem>
            <ListItem>
              You grant Veerge a non-exclusive, transferable, sub-licensable, royalty-free,
              worldwide right and license to use the names, trademarks, service marks and logos
              associated with your Store (“Your Trademarks”) to operate, provide, and promote the
              Services and to perform our obligations and exercise our rights under the Terms of
              Service. This license will survive any termination of the Terms of Service solely to
              the extent that Veerge requires the license to exercise any rights or perform any
              obligations that arose during the Term.
            </ListItem>
          </OrderedList>
          <Text className="content_subhead">8.2. Veerge Intellectual Property</Text>
          <OrderedList className="content">
            <ListItem>
              You agree that you may not use any trademarks, logos, or service marks of Veerge,
              whether registered or unregistered, unless you are authorized to do so by Veerge in
              writing. You agree not to use or adopt any marks that may be considered confusing with
              the Veerge Trademarks. You agree that any variations or misspellings of the Veerge
              Trademarks would be considered confusing with the Veerge Trademarks.
            </ListItem>
            <ListItem>
              You agree not to purchase, register, or use search engine or other pay-per-click
              keywords (such as Google Ads), trademarks, email addresses, social media names, or
              domain names (including without limitation top-level domains, sub-domains, and page
              URLs) that use or include Veerge or Veerge Trademarks or that use or include any terms
              that may be confusing with the Veerge Trademarks.
            </ListItem>
            <ListItem>
              You acknowledge and agree that the Terms of Service do not give you any right to
              implement Veerge patents.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="beta" ref={betaRef}>
          <Text className="content_head">Beta Services</Text>
          <Text className="content">
            From time to time, Veerge may, in its sole discretion, invite you to use, on a trial
            basis, pre-release or beta features that are in development and not yet available to all
            property development companies (“Beta Services”). Beta Services are not part of the
            Services, and Beta Services may be subject to additional terms and conditions, which
            Veerge will provide to you prior to your use of the Beta Services. Such Beta Services
            and all associated conversations and materials relating thereto will be considered
            Veerge Confidential Information and subject to the confidentiality provisions in this
            agreement. Without limiting the generality of the foregoing, you agree that you will not
            make any public statements or otherwise disclose your participation in the Beta Services
            without Veerge’s prior written consent. Veerge makes no representations or warranties
            that the Beta Services will function. Veerge may discontinue the Beta Services at any
            time in its sole discretion. Veerge will have no liability for any harm or damage
            arising out of or in connection with a Beta Service. The Beta Services may not work in
            the same way as a final version. Veerge may change or not release a final or commercial
            version of a Beta Service in our sole discretion
          </Text>
        </ListItem>

        <ListItem id="feedback" ref={feedbackRef}>
          <Text className="content_head">Feedback and Reviews</Text>
          <Text className="content">
            Veerge welcomes any ideas and/or suggestions regarding improvements or additions to the
            Services. Under no circumstances will any disclosure of any idea, suggestion or related
            material or any review of the Services, Third Party Services or any Third Party Provider
            (collectively, “Feedback”) to Veerge be subject to any obligation of confidentiality or
            expectation of compensation. By submitting Feedback to Veerge (whether submitted
            directly to Veerge or posted on any Veerge hosted forum or page), you waive any and all
            rights in the Feedback and that Veerge is free to implement and use the Feedback if
            desired, as provided by you or as modified by Veerge, without obtaining permission or
            license from you or from any third party. Any reviews of a Third Party Service or Third
            Party Provider that you submit to Veerge must be accurate to the best of your knowledge,
            and must not be illegal, obscene, threatening, defamatory, invasive of privacy,
            infringing of intellectual property rights, or otherwise injurious to third parties or
            objectionable. Veerge reserves the right (but not the obligation) to remove or edit
            Feedback of Third Party Services or Third Party Providers, but does not regularly
            inspect posted Feedback.
          </Text>
        </ListItem>

        <ListItem id="privacy" ref={privacyRef}>
          <Text className="content_head">Privacy Policy</Text>
          <Text className="content">
            Veerge is firmly committed to protecting the privacy of your personal information and
            the personal information of your customers. By using the Service, you acknowledge and
            agree that Veerge’s collection, usage and disclosure of this personal information is
            governed by our Privacy Policy.
          </Text>
        </ListItem>

        <ListItem id="terms" ref={termsRef}>
          <Text className="content_head">Term and Termination</Text>
          <OrderedList className="content">
            <ListItem>
              The term of these Terms of Service will begin on the date of your completed
              registration for use of a Service and continue until terminated by us or by you, as
              provided below (the “Term”).
            </ListItem>
            <ListItem>
              You may cancel your Account and terminate the Terms of Service at any time by
              contacting Veerge Support and then following the specific instructions indicated to
              you in Veerge’s response.
            </ListItem>
            <ListItem>
              Without limiting any other remedies, we may suspend or terminate your Account or the
              Terms of Service for any reason, without notice and at any time (unless otherwise
              required by law), including if we suspect that you (by conviction, settlement,
              insurance or escrow investigation, or otherwise) have engaged in fraudulent activity
              in connection with the use of the Services. Termination of the Terms of Service will
              be without prejudice to any rights or obligations which arose prior to the date of
              termination.
            </ListItem>
            <ListItem>
              Upon termination of the Services by either party for any reason:
              <OrderedList type="a" className="content">
                <ListItem>
                  Veerge will cease providing you with the Services and you will no longer be able
                  to access your Account;
                </ListItem>
                <ListItem>
                  unless otherwise provided in the Terms of Service, you will not be entitled to any
                  refunds of any Fees, pro rata or otherwise;
                </ListItem>
                <ListItem>
                  any outstanding balance owed to Veerge for your use of the Services through the
                  effective date of such termination will immediately become due and payable in
                  full; and
                </ListItem>
                <ListItem>your Web Application will be taken offline.</ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              If you purchased a domain name through Veerge, upon cancellation your domain will no
              longer be automatically renewed. Following termination, it will be your sole
              responsibility to handle all matters related to your domain with the domain provider.
            </ListItem>
            <ListItem>
              If there are any outstanding Fees owed by you at the date of termination of the
              Service, you will receive one final invoice via email. Once that invoice has been paid
              in full, you will not be charged again.
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="modification" ref={modificationRef}>
          <Text className="content_head">Modifications</Text>
          <OrderedList className="content">
            <ListItem>
              We reserve the right, in our sole and absolute discretion, to update or change any
              portion of the Terms of Service at any time. We will provide you with reasonable
              advance notice of changes to the Terms of Service that materially adversely affect
              your use of the Services or your rights under the Terms of Service by sending an email
              to the Primary Email Address, providing notice through the Veerge administrative
              console, or by similar means. However, Veerge may make changes that materially
              adversely affect your use of the Services or your rights under the Terms of Service at
              any time and with immediate effect (i) for legal, regulatory, fraud and abuse
              prevention, or security reasons; or (ii) to restrict products or activities that we
              deem unsafe, inappropriate, or offensive. Unless we indicate otherwise in our notice
              (if applicable), any changes to the Terms of Service will be effective immediately
              upon posting of such updated terms at this location. Your continued access to or use
              of the Services after we provide such notice, if applicable, or after we post such
              updated terms, constitutes your acceptance of the changes and consent to be bound by
              the Terms of Service as amended. If you do not agree to the amended Terms of Service,
              you must stop accessing and using the Services.
            </ListItem>
            <ListItem>
              Veerge may change the Fees for the Services from time-to-time. We will provide you
              with 30 days advanced notice prior to any changes in Fees by sending an email to the
              Primary Email Account, providing notice through the Veerge administrative console, or
              by similar means. Veerge will not be liable to you or to any third party for any
              modification, price change, suspension or discontinuance of the Services (or any part
              thereof).
            </ListItem>
          </OrderedList>
        </ListItem>

        <ListItem id="general" ref={generalRef}>
          <Text className="content_head">General Conditions</Text>
          <OrderedList className="content">
            <ListItem>
              The Terms of Service, including the documents it incorporates by reference, constitute
              the entire agreement between you and Veerge and govern your use of the Services and
              your Account, superseding any prior agreements between you and Veerge (including, but
              not limited to, any prior versions of the Terms of Service).
            </ListItem>
            <ListItem>
              The failure of Veerge to exercise or enforce any right or provision of the Terms of
              Service will not constitute a waiver of such right or provision. If any provision of
              the Terms of Service, including all terms and conditions and other documents it
              incorporates by reference, is held by a court of competent jurisdiction to be contrary
              to law, such provision will be changed and interpreted so as to best accomplish the
              objectives of the original provision to the fullest extent allowed by law, and the
              remaining provision of the Terms of Service will remain in full force and effect.
            </ListItem>
            <ListItem>
              Save for Veerge and its affiliates, you or anyone accessing Veerge Services pursuant
              to these Terms of Service, unless otherwise provided in these Terms of Service, no
              person or entity who is not a party to these Terms of Service will have any right to
              enforce any term of these Terms of Service, regardless of whether such person or
              entity has been identified by name, as a member of a class or as answering a
              particular description. For the avoidance of doubt, this will not affect the rights of
              any permitted assignee or transferee of these Terms.
            </ListItem>
            <ListItem>
              The Terms of Service shall be governed by and construed in accordance with the laws of
              the State of Delaware, United States, without giving effect to any principles of
              conflicts of laws. Any legal actions, suits, or proceedings arising out of or relating
              to the Terms of Service shall be brought in a court of competent jurisdiction located
              in Delaware, United States.
            </ListItem>
            <ListItem>
              The Terms of Service may be available in languages other than English. To the extent
              of any inconsistencies or conflicts between these English Terms of Service and
              Veerge’s Terms of Service available in another language, the most current English
              version of the Terms of Service at
              <a
                href="https://www.veerge.myxellia.io/legal/terms"
                as="span"
                textDecoration={'underline'}
              >
                {' '}
                https://www.veerge.myxellia.io/legal/terms
              </a>{' '}
              will prevail. Any disputes arising out of these Terms of Service will be resolved in
              English unless otherwise determined by Veerge (acting in its sole discretion) or as
              required by applicable law.
            </ListItem>
            <ListItem>
              All the terms and provisions of the Terms of Service will be binding upon and inure to
              the benefit of the parties to the Terms of Service and to their respective heirs,
              successors, permitted assigns and legal representatives. Veerge will be permitted to
              assign these Terms of Service without notice to you or consent from you. You will have
              no right to assign or otherwise transfer the Terms of Service, or any of your rights
              or obligations hereunder, to any third party without Veerge’s prior written consent,
              to be given or withheld in Veerge’s sole discretion.
            </ListItem>
            <ListItem>
              If any provision, or portion of the provision, in these Terms of Service is, for any
              reason, held to be invalid, illegal or unenforceable in any respect, then such
              invalidity, illegality or unenforceability will not affect any other provision (or the
              unaffected portion of the provision) of the Terms of Service, and the Terms of Service
              will be construed as if such invalid, illegal or unenforceable provision, or portion
              of the provision, had never been contained within the Terms of Service.
            </ListItem>
            <ListItem>
              On termination, all related rights and obligations under the Terms of Service
              immediately terminate, except that (a) you will remain responsible for performing all
              of your obligations in connection with transactions entered into before termination
              and for any liabilities that accrued before or as a result of termination; and
              Sections 1 (Account Terms), 5 (Payment of Fees), 6 (Confidentiality), 7 (Limitation of
              Liability and Indemnification), 8.1 (Intellectual Property and Your Materials), 10
              (Feedback and Reviews), 11 (Privacy and Data Protection), 12 (Cancellation and
              Termination), 13(1) (Modifications), and 14 (General Conditions) will survive the
              termination or expiration of these Terms of Service.
            </ListItem>
          </OrderedList>
        </ListItem>
      </OrderedList>
    </div>
  );
};
