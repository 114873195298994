import { Box, Center, Divider, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import comment from '../../assets/icons/comment-icon.svg';
import comment_white from '../../assets/icons/comment-icon-white.svg';
import veerge_support from '../../assets/icons/veerge_support.svg';
import veerge_support_white from '../../assets/icons/veerge_support-white.svg';

const QuestionsAndReplies = ({ questions, white }) => {
  const colors = ['#FF9103', '#1D6169', '#4545FE']
  return (
    <Box pt="30px" id="questions">
      <Box border={'0.5px solid #323338'} background={'#0D0D0D'} borderRadius={'5px'} color={'white'}>
        <Flex align='center' gap='10px' px='15px' py='20px'>
          {<Image src={comment_white} />}
          <Text fontSize={'16px'}>Questions and replies</Text>
        </Flex>
        <Divider w='full' />
        {questions.map(question => (
          <Box px='15px' py='20px'>
            <Flex gap='10px' align={'center'}>
              {question?.image ? (
                <Image w='40px' h='40px' borderRadius={'full'} src={question?.image} />
              ) : (
                <Center color={'white'} bg={colors[0]} borderRadius={'full'} w='40px' h='40px'>
                  {question?.name[0]}
                </Center>
              )}
              <VStack align={'stretch'} w='70%' spacing={'2px'}>
                <Text fontSize={'16px'} fontWeight={500}>
                  {question?.name}
                </Text>
                <Text  fontSize={'12px'} fontWeight={300} noOfLines={1}>
                  {question?.date}
                </Text>
              </VStack>
            </Flex>
            <Text mt='17px' fontSize={'12px'}>{question?.content}</Text>
            <Box
              mt='22px'
              p={'1rem'}
              bg={'#232425'} borderRadius={'8px'}
            >
              {question?.replies?.map(reply => (
                <>
                  <Box mt='16px'>
                    <Flex gap='10px' alignItems={'center'}>
                    <Center borderRadius={'full'} bg='black' w='38px' h='38px'>
                          <Image w='30px' h='30px' borderRadius={'full'} src={veerge_support} />
                        </Center>
                      <VStack align={'stretch'} w='70%' spacing={'1px'}>
                        <Text  fontSize={'16px'} fontWeight={500}>
                          Veerge Support
                        </Text>
                        <Text fontSize={'12px'}  noOfLines={1}>
                          {question?.date}
                        </Text>
                      </VStack>
                    </Flex>
                  </Box>
                  <Text  mt='17px' fontSize={'12px'}>{reply}</Text>
                </>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box >
  )
}

export default QuestionsAndReplies