import introToVeerge from '../assets/images/home/intro_to_veerge.png';
import firstTime from '../assets/images/home/first_time.png';
import approvedWhat from '../assets/images/home/approved_what.png';
import listings from '../assets/images/home/listings.png';
import creatingAn from '../assets/images/home/creating_an_account.png';
import invitingTeam from '../assets/images/home/inviting_team.png';
import webApplication from '../assets/images/home/web_application.png';
import tickets from '../assets/images/home/tickets.png';
import veergePlus from '../assets/images/home/veerge_plus.png';
import protectedImg from '../assets/images/home/protected.png';
import agentGuide from '../assets/images/home/agent_guide.png';
import themes from '../assets/images/home/themes.png';

const data = [
  {
    img: introToVeerge,
    title: 'INTRO TO VEERGE',
    color: 'black',
    tag: 'Beginner',
    readTime: '6 mins read',
    datePosted: 'Nov 23, 2022',
    path: '/intro_to_veerge',
  },
  {
    img: firstTime,
    title: 'FIRST TIME SET UP',
    color: 'black',
    tag: 'Beginner',
    readTime: '5 mins read',
    datePosted: 'Dec 09, 2022',
    path: '/first_time_setup',
  },
  {
    img: approvedWhat,
    title: 'APPROVED, WHAT NEXT?',
    color: 'black',
    tag: 'Beginner',
    readTime: '4 mins read',
    datePosted: 'Jan 06, 2023',
    path: '/approved_what_next',
  },
  {
    img: listings,
    title: 'LISTINGS',
    color: 'black',
    tag: 'Advanced',
    readTime: '8 mins read',
    datePosted: 'Feb 18, 2023',
    path: '/listings/overview',
  },
  {
    img: creatingAn,
    title: 'LEADS & ACCOUNT',
    color: 'black',
    tag: 'Intermediate',
    readTime: '5 mins read',
    datePosted: 'Mar 26, 2023',
    path: '/lead/create_account',
  },
  {
    img: invitingTeam,
    title: 'INVITING TEAM MEMBERS',
    color: 'black',
    tag: 'Intermediate',
    readTime: '5 mins read',
    datePosted: 'Apr 02, 2023',
    path: '/invite_team_members',
  },
  {
    img: webApplication,
    title: 'END-TO-END EXPERIENCE',
    color: 'white',
    tag: 'Intermediate',
    readTime: '5 mins read',
    datePosted: 'May 11, 2023',
    path: '/users_experience',
  },
  {
    img: tickets,
    title: 'WHY A TICKETING SYSTEM?',
    color: 'white',
    tag: 'Intermediate',
    readTime: '5 mins read',
    datePosted: 'Jun 09, 2023',
    path: '/ticketing',
  },
  {
    img: veergePlus,
    title: 'VEERGE PLUS',
    color: 'black',
    tag: 'Intermediate',
    readTime: '5 mins read',
    datePosted: 'Jul 21, 2023',
    path: '/veerge_plus',
  },
  {
    img: themes,
    title: 'THEMES',
    color: 'white',
    tag: 'Intermediate',
    readTime: '6 mins read',
    datePosted: 'Aug 12, 2023',
    path: '/veerge_themes',
  },
  {
    img: protectedImg,
    title: 'HOW ARE YOU PROTECTED',
    color: 'black',
    tag: 'Intermediate',
    readTime: '6 mins read',
    datePosted: 'Sep 29, 2023',
    path: '/how_protected',
  },
  {
    img: agentGuide,
    title: 'REALTORS GUIDE',
    color: 'black',
    tag: 'Intermediate',
    readTime: '6 mins read',
    datePosted: 'Nov 1, 2022',
    path: '/realtor/portal',
  },
];

export default data;
