// export const searchData = [
//   {
//     route: "/intro_to_veerge",
//     searchQuery: "Introduction veerge system",
//     topic: "Intro to Veerge",
//   },
//   {
//     route: "/create_online_store",
//     searchQuery: "Create store how to",
//     topic: "How to create an online store",
//   },
//   {
//     route: "/first_time_setup",
//     searchQuery: "Setup veerge how to",
//     topic: "How to set up a veerge account",
//   },
//   {
//     route: "/first_time_setup",
//     searchQuery: "Password setup how to",
//     topic: "How to set up a veerge account",
//   },
//   {
//     route: "/listings/create_listing",
//     searchQuery: "Listings create how to",
//     topic: "How to create listings",
//   },

//   {
//     route: "/listings/create_unit",
//     searchQuery: "Allocation unit create how to",
//     topic: "How to create unit allocations",
//   },
//   {
//     route: "/create_client_account",
//     searchQuery: "Client account signup create how to",
//     topic: "How create client account",
//   },
//   {
//     route: "/listings/archive_unit",
//     searchQuery: "Archive how to",
//     topic: "How to archive unit ",
//   },
//   {
//     route: "/agents_signup",
//     searchQuery: "Agents signup how to",
//     topic: "How to sign up as an agent",
//   },
//   {
//     route: "/listings/fractionalize_asset#frac",
//     searchQuery: "Fraction how to",
//     topic: "How users buy fractions",
//   },
//   {
//     route: "/fractional_real_estate#asset",
//     searchQuery: "asset fraction",
//     topic: "Asset management",
//   },
//   {
//     route: "/fractional_real_estate",
//     searchQuery: "Real estate fraction",
//     topic: "Fractional real estate",
//   },
//   {
//     route: "/inspections",
//     searchQuery: "inspect property",
//     topic: "Inspections on Veerge",
//   },
//   {
//     route: "/change_contact",
//     searchQuery: "contact how",
//     topic: "Change contact person",
//   },
// ];

//prettier-ignore
export const helpSearchData = [
  { pageTitle: 'Agent Process', page: 'AgentProcess', link: '/realtor/process', id: 'agents', title: 'Agents on Veerge' },
  { pageTitle: 'Agent Process', page: 'AgentProcess', link: '/realtor/process', id: 'referral', title: 'Referral link and users connections' },
  { pageTitle: 'Agent Process', page: 'AgentProcess', link: '/realtor/process', id: 'commission', title: 'Commission requests and transactions' },
  { pageTitle: 'Agent Process', page: 'AgentProcess', link: '/realtor/process', id: 'approving', title: 'Approving commissions: A step-by-step guide' },
  { pageTitle: 'Agent Portal', page: 'AgentPortal', link: '/realtor/portal', id: 'overview', title: 'Overview of agent portal' },
  { pageTitle: 'Agent Portal', page: 'AgentPortal', link: '/realtor/portal', id: 'howAgent', title: 'How Agent portal works' },
  { pageTitle: 'Application Guide', page: 'ApplicationGuide', link: '/application_guide', id: 'the_veerge', title: 'The Veerge Dilemma' },
  { pageTitle: 'Application Guide', page: 'ApplicationGuide', link: '/application_guide', id: 'unleashing', title: 'Unleashing the power of Basic Application.' },
  { pageTitle: 'Application Guide', page: 'ApplicationGuide', link: '/application_guide', id: 'enter', title: 'Enter the realm of custom application' },
  { pageTitle: 'Application Guide', page: 'ApplicationGuide', link: '/application_guide', id: 'the_shuwdown', title: 'The Showdown: Basic vs. Custom Veerge Applications' },
  { pageTitle: 'Application Guide', page: 'ApplicationGuide', link: '/application_guide', id: 'making', title: 'Making the right move' },
  { pageTitle: 'Approved', page: 'Approved', link: '/approved_what_next', id: 'approved', title: 'Approved, What Next??' },
  { pageTitle: 'Approved', page: 'Approved', link: '/approved_what_next', id: 'business', title: 'Business Migration Process' },
  { pageTitle: 'Approved', page: 'Approved', link: '/approved_what_next', id: 'migration', title: 'Data Migration Process' },
  { pageTitle: 'Approved', page: 'Approved', link: '/approved_what_next', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Fisrt Timesetup', page: 'Fisrttimesetup', link: '/first_time_setup', id: 'account', title: 'First Time Setup' },
  { pageTitle: 'Fisrt Timesetup', page: 'Fisrttimesetup', link: '/first_time_setup', id: 'steps', title: 'Steps' },
  { pageTitle: 'Fisrt Timesetup', page: 'Fisrttimesetup', link: '/first_time_setup', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Intro to Veerge', page: 'Introtoveerge', link: '/intro_to_veerge', id: 'veerge', title: 'Overview of Veerge?' },
  { pageTitle: 'Intro to Veerge', page: 'Introtoveerge', link: '/intro_to_veerge', id: 'engagement', title: 'System of engagement' },
  { pageTitle: 'Intro to Veerge', page: 'Introtoveerge', link: '/intro_to_veerge', id: 'trust', title: 'System of Trust' },
  { pageTitle: 'Intro to Veerge', page: 'Introtoveerge', link: '/intro_to_veerge', id: 'intelligence', title: 'System of Intelligence' },
  { pageTitle: 'Intro to Veerge', page: 'Introtoveerge', link: '/intro_to_veerge', id: 'conclusion', title: 'Conclusion' },
  { pageTitle: 'Invite Team Members', page: 'InviteTeamMembers', link: '/invite_team_members', id: 'invite', title: 'Invite Team Members' },
  { pageTitle: 'Invite Team Members', page: 'InviteTeamMembers', link: '/invite_team_members', id: 'steps', title: 'Steps' },
  { pageTitle: 'Invite Team Members', page: 'InviteTeamMembers', link: '/invite_team_members', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Create Lead Account', page: 'CreateLeadAccount', link: '/lead/create_account', id: 'how_to', title: 'Create an account' },
  { pageTitle: 'Create Lead Account', page: 'CreateLeadAccount', link: '/lead/create_account', id: 'steps', title: 'Steps' },
  { pageTitle: 'Create Lead Account', page: 'CreateLeadAccount', link: '/lead/create_account', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Home Owners Packet', page: 'HomeOwnersPacket', link: '/lead/home_owners_packet', id: 'how_to', title: 'Home owner’s Packet' },
  { pageTitle: 'Home Owners Packet', page: 'HomeOwnersPacket', link: '/lead/home_owners_packet', id: 'steps', title: 'Steps' },
  { pageTitle: 'Home Owners Packet', page: 'HomeOwnersPacket', link: '/lead/home_owners_packet', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Send An Offer', page: 'SendAnOffer', link: '/lead/send_an_offer', id: 'how_to', title: 'Create an account' },
  { pageTitle: 'Send An Offer', page: 'SendAnOffer', link: '/lead/send_an_offer', id: 'steps', title: 'Steps' },
  { pageTitle: 'Send An Offer', page: 'SendAnOffer', link: '/lead/send_an_offer', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Archive Unit', page: 'Archiveunit', link: '/listings/archive_unit', id: 'how_to', title: 'How to archive a unit' },
  { pageTitle: 'Archive Unit', page: 'Archiveunit', link: '/listings/archive_unit', id: 'how_to_remove', title: 'How to remove a unit from achieve' },
  { pageTitle: 'Archive Unit', page: 'Archiveunit', link: '/listings/archive_unit', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Change Listing', page: 'Changelisting', link: '/listings/change_listing', id: 'how_to', title: 'How to change a listing information ' },
  { pageTitle: 'Change Listing', page: 'Changelisting', link: '/listings/change_listing', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Create Listing', page: 'Createlisting', link: '/listings/create_listing', id: 'overview', title: 'Overview' },
  { pageTitle: 'Create Listing', page: 'Createlisting', link: '/listings/create_listing', id: 'what_you_need', title: 'What you need to know' },
  { pageTitle: 'Create Listing', page: 'Createlisting', link: '/listings/create_listing', id: 'steps', title: 'Steps' },
  { pageTitle: 'Create Listing', page: 'Createlisting', link: '/listings/create_listing', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Create Unit', page: 'Createunit', link: '/listings/create_unit', id: 'how_to', title: 'How to create unit  allocation' },
  { pageTitle: 'Create Unit', page: 'Createunit', link: '/listings/create_unit', id: 'steps', title: 'Steps' },
  { pageTitle: 'Create Unit', page: 'Createunit', link: '/listings/create_unit', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Delist', page: 'Delist', link: '/listings/delist', id: 'how_to', title: 'How to delist a listing' },
  { pageTitle: 'Delist', page: 'Delist', link: '/listings/delist', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Fraction Asset', page: 'Fractionasset', link: '/listings/fractionalize_asset', id: 'how_to', title: 'How to fractionalise an asset' },
  { pageTitle: 'Fraction Asset', page: 'Fractionasset', link: '/listings/fractionalize_asset', id: 'questions', title: 'Questions & Replies' },
  { pageTitle: 'Veerge Overview', page: 'Overview', link: '/listings/overview', id: 'overview', title: 'Overview of listings' },
  { pageTitle: 'Veerge Overview', page: 'Overview', link: '/listings/overview', id: 'listings', title: 'Listings & Units' },
  { pageTitle: 'Veerge Overview', page: 'Overview', link: '/listings/overview', id: 'payment', title: 'Payment plan' },
  { pageTitle: 'Veerge Overview', page: 'Overview', link: '/listings/overview', id: 'allocations', title: 'Allocations' },
  { pageTitle: 'Veerge Overview', page: 'Overview', link: '/listings/overview', id: 'archive', title: 'Archive a unit' },
  { pageTitle: 'Veerge Overview', page: 'Overview', link: '/listings/overview', id: 'inspections', title: 'Inspections' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'introduction', title: 'Introduction' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'our_values', title: 'Our Values' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'why_we', title: 'Why We Process Your Information' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'your_rights', title: 'Your Rights Over your Infromation' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'retention', title: 'Retention Of Your Information' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'our_user', title: 'Our Use of Machine Learning' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'protection', title: 'Protection of Your Information' },
  { pageTitle: 'Privacy', page: 'Privacy', link: '/privacy', id: 'contacting', title: 'Contacting Us' },
  { pageTitle: 'How you are Protected', page: 'Protected', link: '/how_protected', id: 'veerge_theme', title: 'How you are protected' },
  { pageTitle: 'How you are Protected', page: 'Protected', link: '/how_protected', id: 'mitigating', title: 'Mitigation against downtime ' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'account_terms', title: 'Account Terms' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'account_activation', title: 'Account Activation' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'veerge', title: 'Veerge Rights' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'your', title: 'Your Responsibilities ' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'payment', title: 'Payment of Fees and Taxes' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'confident', title: 'Confidentiality' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'limitation', title: 'Limitation of Liability and Indemnification ' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'intellectual', title: 'Intellectual Property and Your Materials' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'beta', title: 'Beta Services' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'feedback', title: 'Feedback and Reviews' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'privacy', title: 'Privacy Policy' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'terms', title: 'Term and Termination' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'modification', title: 'Modifications' },
  { pageTitle: 'Terms', page: 'Terms', link: '/terms', id: 'general', title: 'General Conditions' },
  { pageTitle: 'Ticketing', page: 'Ticketing', link: '/ticketing', id: 'overview', title: 'Overview of Ticketing system' },
  { pageTitle: 'Ticketing', page: 'Ticketing', link: '/ticketing', id: 'perks', title: 'Perks of a ticketing system tailored for property development companies' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'personalize', title: 'Personalize More, Retain More, Move Fast, Never Stop' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'optimize', title: 'Optimized For Performance' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'migrate', title: 'Migrate and Launch with Ease' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'built', title: 'Built-in Security and Insights' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'committed', title: 'Committed  to Support' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'channels', title: 'Channels' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'pricing', title: 'Pricing and Cost Breakdown' },
  { pageTitle: 'Veerge Plus', page: 'VeergePlus', link: '/veerge_plus', id: 'getting', title: 'Getting Started with Veerge' },
  { pageTitle: 'Veerge Themes', page: 'VeergeThemes', link: '/veerge_themes', id: 'veerge_theme', title: 'Veerge Theme' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'authentication', title: 'AUTHENTICATION' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'after_authentication', title: 'AFTER AUTHENTICATION, WHAT NEXT ?' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'purchase_flow', title: 'PURCHASE FLOW' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'revenue_recogniition', title: 'REVENUE RECOGNITION SOLUTION' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'smart_alloc', title: 'SMART ALLOCATION' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'auto_pay', title: 'AUTO - PAY' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'co_ownership', title: 'CO - OWNERSHIP' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'fractional_ownership', title: 'FRACTIONAL OWNERSHIP' },
  { pageTitle: 'End-to-End Experience', page: 'Usersexperience', link: '/users_experience', id: 'agent', title: 'AGENT' },
]

//prettier-ignore
export const blogSearchData = [
  { pageTitle: 'Fractional Ownership', page: 'Fractional_ownership', link: '/blog/fractional_ownership', id: 'overview', title: 'Overview' },
  { pageTitle: 'Fractional Ownership', page: 'Fractional_ownership', link: '/blog/fractional_ownership', id: 'how_fractional', title: 'How fractional works the Veerge way' },
  { pageTitle: 'Inventory', page: 'Inventory', link: '/blog/inventory', id: 'overview', title: 'Overview' },
  { pageTitle: 'Inventory', page: 'Inventory', link: '/blog/inventory', id: 'benefit', title: 'Benefits of this system' },
  { pageTitle: 'Lead Management', page: 'Lead_management', link: '/blog/lead_management', id: 'overview', title: 'Overview' },
  { pageTitle: 'Lead Management', page: 'Lead_management', link: '/blog/lead_management', id: 'benefit', title: 'Benefits of this system' },
  { pageTitle: 'New Era', page: 'New_era', link: '/blog/new_era', id: 'idea_for', title: 'Idea for innovation' },
  { pageTitle: 'New Era', page: 'New_era', link: '/blog/new_era', id: 'what_is', title: 'What is Veerge?' },
  { pageTitle: 'New Era', page: 'New_era', link: '/blog/new_era', id: 'how_does', title: 'How does Veerge deliver those values effectively' },
  { pageTitle: 'Revenue Recognition', page: 'Revenue_recognition', link: '/blog/revenue_recognition', id: 'overview', title: 'Overview' },
  { pageTitle: 'Revenue Recognition', page: 'Revenue_recognition', link: '/blog/revenue_recognition', id: 'benefit', title: 'Benefits' },
  { pageTitle: 'Transforming Business', page: 'Transforming_business', link: '/blog/transforming_business', id: 'a_ticket', title: 'A ticket system serves as an indispensable tool within the realm' },
  { pageTitle: 'Transforming Business', page: 'Transforming_business', link: '/blog/transforming_business', id: 'perks', title: 'Perks of a Ticketing System' },
  { pageTitle: 'Veerge Data', page: 'Veerge_data', link: '/blog/veerge_data', id: 'overview', title: 'Overview' },
  { pageTitle: 'Veerge Data', page: 'Veerge_data', link: '/blog/veerge_data', id: 'machine_leaning', title: 'Machine Learning, Deep Learning & AI' },
  { pageTitle: 'Veerge Data', page: 'Veerge_data', link: '/blog/veerge_data', id: 'why_veerge', title: 'Why Veerge Matters' },
  { pageTitle: 'Veerge Data', page: 'Veerge_data', link: '/blog/veerge_data', id: 'conclusion', title: 'Conclusion' },
  { pageTitle: 'Why Veerge', page: 'Why_veerge', link: '/blog/why_veerge', id: 'why_veerge', title: 'Why Veerge' },
  { pageTitle: 'Why Veerge', page: 'Why_veerge', link: '/blog/why_veerge', id: 'our_mission', title: 'Our Mission' },
  { pageTitle: 'Why Veerge', page: 'Why_veerge', link: '/blog/why_veerge', id: 'our_value', title: 'Our Value' },
  { pageTitle: 'Why Veerge', page: 'Why_veerge', link: '/blog/why_veerge', id: 'our_culture', title: 'Our Culture' },
]
