import new_era from '../assets/images/blog/new_era.png';
import a_ticketing_system from '../assets/images/blog/a_ticketing_system.png';
import fractional_ownership from '../assets/images/blog/fractional_ownership.png';
import inventory_management from '../assets/images/blog/inventory_management.png';
import lead_management from '../assets/images/blog/lead_management.png';
import letter_from_CEO from '../assets/images/blog/letter_from_CEO.png';
import revenue_recognition from '../assets/images/blog/revenue_recognition.png';
import smart_payment from '../assets/images/blog/smart_payment.png';
import veergeNDdata from '../assets/images/blog/veerge&data.png';
import why_veerge from '../assets/images/blog/why_veerge.png';

export const blogDataRead = [
    {
        img: new_era,
        title: 'New era of Real Estate',
        type: 'Peaks',
        readTime: '6mins read',
        datePosted: 'Mar 07, 2023',
        path: '/blog/new_era'
    },
    {
        img: why_veerge,
        title: 'Why Veerge instead of building',
        type: 'Peaks',
        readTime: '5mins read',
        datePosted: 'Feb 27, 2023',
        path: '/blog/why_veerge'
    },
    {
        img: veergeNDdata,
        title: 'Veerge & Data analytics',
        type: 'Features',
        readTime: '8mins read',
        datePosted: 'Aug 10, 2023',
        path: '/blog/veerge_data'
    },
    {
        img: fractional_ownership,
        title: 'Fractional ownership',
        type: 'Peaks',
        readTime: '5mins read',
        datePosted: 'Jan 21, 2023',
        path: '/blog/fractional_ownership'
    },
    {
        img: fractional_ownership,
        title: 'Understanding fractional real estate',
        type: 'Industry Insight',
        readTime: '3mins read',
        datePosted: 'Dec 16, 2022',
        path: '/blog/understanding_fraction'
    },
    {
        img: letter_from_CEO,
        title: 'A letter from the CEO',
        type: 'Culture',
        readTime: '2mins read',
        datePosted: 'Nov 20, 2022',
        path: '/blog/a_letter'
    },
    {
        img: lead_management,
        title: 'Lead management system',
        type: 'Features',
        readTime: '2mins read',
        datePosted: 'Jun 22, 2023',
        path: '/blog/lead_management'
    },
    {
        img: a_ticketing_system,
        title: 'A ticketing system serves as...',
        type: 'Features',
        readTime: '4mins read',
        datePosted: 'May 20, 2023',
        path: '/blog/transforming_business'
    },
    {
        img: inventory_management,
        title: 'Inventory management system',
        type: 'Features',
        readTime: '2mins read',
        datePosted: 'Apr 04, 2023',
        path: '/blog/inventory'
    },
    {
        img: revenue_recognition,
        title: 'Revenue recognition solution',
        type: 'Features',
        readTime: '3mins read',
        datePosted: 'Jul 15, 2023',
        path: '/blog/revenue_recognition'
    },
    {
        img: smart_payment,
        title: 'Smart payment plan',
        type: 'Features',
        readTime: '7mins read',
        datePosted: 'Sep 25, 2023',
        path: '/blog/smart_payment'
    },
]

export const blogDataRecent = [
    {
        img: smart_payment,
        title: 'Smart payment plan',
        type: 'Features',
        readTime: '7mins read',
        datePosted: 'Sep 25, 2023',
        path: '/blog/smart_payment'
    },
    {
        img: veergeNDdata,
        title: 'Veerge & Data analytics',
        type: 'Features',
        readTime: '8mins read',
        datePosted: 'Aug 10, 2023',
        path: '/blog/veerge_data'
    },
    {
        img: revenue_recognition,
        title: 'Revenue recognition solution',
        type: 'Features',
        readTime: '3mins read',
        datePosted: 'Jul 15, 2023',
        path: '/blog/revenue_recognition'
    },
    {
        img: lead_management,
        title: 'Lead management system',
        type: 'Features',
        readTime: '2mins read',
        datePosted: 'Jun 22, 2023',
        path: '/blog/lead_management'
    },
    {
        img: a_ticketing_system,
        title: 'A ticketing system serves as...',
        type: 'Features',
        readTime: '4mins read',
        datePosted: 'May 20, 2023',
        path: '/blog/transforming_business'
    },
    {
        img: inventory_management,
        title: 'Inventory management system',
        type: 'Features',
        readTime: '2mins read',
        datePosted: 'Apr 04, 2023',
        path: '/blog/inventory'
    },
    {
        img: new_era,
        title: 'New era of Real Estate',
        type: 'Peaks',
        readTime: '6mins read',
        datePosted: 'Mar 07, 2023',
        path: '/blog/new_era'
    },
    {
        img: why_veerge,
        title: 'Why Veerge instead of building',
        type: 'Peaks',
        readTime: '5mins read',
        datePosted: 'Feb 27, 2023',
        path: '/blog/why_veerge'
    },
    {
        img: fractional_ownership,
        title: 'Fractional ownership',
        type: 'Peaks',
        readTime: '5mins read',
        datePosted: 'Jan 21, 2023',
        path: '/blog/fractional_ownership'
    },
    {
        img: fractional_ownership,
        title: 'Understanding fractional real estate',
        type: 'Industry Insight',
        readTime: '3mins read',
        datePosted: 'Dec 16, 2022',
        path: '/blog/understanding_fraction'
    },
    {
        img: letter_from_CEO,
        title: 'A letter from the CEO',
        type: 'Culture',
        readTime: '2mins read',
        datePosted: 'Nov 20, 2022',
        path: '/blog/a_letter'
    },
]